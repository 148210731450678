<template>
  <div v-if="usersData" class="table-container">
    <table class="my-table">
      <thead>
        <tr>
          <th rowspan="2">#</th>
          <th colspan="4">{{ $t("Users.data") }}</th>
          <th colspan="3">{{ $t("Debts.data") }}</th>
          <th rowspan="2"><i class="fas fa-sliders-h"></i></th>
        </tr>
        <tr>
          <th>{{ $t("general.image") }}</th>
          <th>{{ $t("general.code") }}</th>
          <th class="cell-lg">{{ $t("general.name") }}</th>
          <th>{{ $t("Users.phone") }}</th>
          <th>{{ $t("general.total") }}</th>
          <th>{{ $t("general.paid") }}</th>
          <th>{{ $t("general.remainding") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(user, index) in usersData" :key="user.userToken">
          <td>{{ ++index + filterData.currentIndex }}</td>
          <td>
            <img
              class="item-img-table"
              :src="fullPathFileFromServer(user.userImagePath, defaultImg)"
              :onerror="`this.src='${defaultImg}'`"
            />
          </td>
          <td>{{ isDataExist(user.fullCode) }}</td>
          <td>
            {{ isDataExist(user.userNameCurrent) }}
          </td>
          <td class="table-phone-number">
            {{ isDataExist(user.userPhoneWithCC) }}
          </td>
          <td>
            {{
              isDataExist(
                user.userFinanceStatisticData.totalDebtsMoneyWithCurrency
              )
            }}
          </td>
          <td>
            {{
              isDataExist(
                user.userFinanceStatisticData.totalPaidMoneyWithCurrency
              )
            }}
          </td>
          <td>
            {{
              isDataExist(
                user.userFinanceStatisticData.totalRemainderMoneyWithCurrency
              )
            }}
          </td>
          <td class="fmenu-item-container">
            <FloatingMenu>
              <li v-if="checkPrivilege(hasDebt())">
                <router-link
                  :to="{
                    name: 'Debts',
                    params: {
                      userToken: user.userToken,
                    },
                  }"
                  :title="$t('Debts.modelName')"
                >
                  <img src="@/assets/images/debts.svg" />
                </router-link>
              </li>
            </FloatingMenu>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {
  checkPrivilege,
  isDataExist,
  fullPathFileFromServer,
} from "./../../../../utils/functions";
import FloatingMenu from "./../../../../components/general/FloatingMenu.vue";
import { hasDebt } from "../../../../utils/privilegeHelper";

export default {
  name: "StudentHasDebtTable",
  mixins: [],
  components: {
    FloatingMenu,
  },
  props: ["usersData", "filterData", "defaultImg"],
  methods: {
    checkPrivilege,
    hasDebt,
    isDataExist,
    fullPathFileFromServer,
  },
};
</script>
